























import { Prop, Component } from 'vue-property-decorator'

import ButtonArrow from '@/components/_uikit/buttons/ButtonArrow.vue'
import StatusIcon from '@/components/StatusIcon.vue'
import Tag from '@/components/_uikit/Tag.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import { MasterLessonShortResource } from '@/store/types'
import AuthModule from '@/store/modules/auth'

@Component({
  components: {
    ButtonArrow,
    StatusIcon,
    Tag,
  },
})
export default class MasterLessonCard extends NotifyMixin {
  @Prop({ required: true })
  private lesson!: MasterLessonShortResource

  private get isCanSeeReview() {
    return !!this.lesson.comment
  }

  private get isDone() {
    return !!this.lesson.checkedAt
  }

  private handleShowReview() {
    this.$emit('review', this.lesson)
  }

  private getTextState(hover: boolean) {
    if (hover) {
      return 'orange--text'
    } else if (this.isDone) {
      return ['teal--text', 'text--lighten-1']
    }
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }
}
