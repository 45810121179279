












































import { Component, Watch } from 'vue-property-decorator'

// components
import Select from '@/components/_uikit/controls/Select.vue'
import MasterLessonCard from '@/components/cards/MasterLessonCard.vue'
import ManagerGroupsModule from '@/store/modules/manager/groups'
import {
  FilterLessonStatus,
  LessonReviewResource,
  MasterEducationMasterGroupIdLessonsGetParams,
  MasterLessonShortResource,
  NameValueResource,
  UserShortResource,
} from '@/store/types'
import DictionaryModule from '@/store/modules/dictionary'
import { formatDate, getCurrentMonth } from '@/utils/functions'
import RatedLessonModal from '@/components/modals/lessons/RatedLessonModal.vue'
import NotifyMixin from '@/mixins/NotifyMixin'

@Component({
  components: {
    MasterLessonCard,
    RatedLessonModal,
    Select,
  },
})
export default class MastersItemLessons extends NotifyMixin {
  private lessons: MasterLessonShortResource[] = []
  private review: LessonReviewResource | null = null
  private showModalRated = false
  private currentMasterGroupID = +this.$route.query.groupID

  private statuses: NameValueResource[] = [
    { name: 'Просмотренные', value: FilterLessonStatus.CHECKED },
    { name: 'Непросмотренные', value: FilterLessonStatus.NOT_CHECKED },
  ]
  private filter: MasterEducationMasterGroupIdLessonsGetParams = {}

  private get user () {
    return ManagerGroupsModule.groupMaster?.user
  }

  private get filterString () {
    return JSON.stringify(this.filter)
  }

  private get master () {
    return ManagerGroupsModule.groupMaster
  }

  private get masterGroups () {
    return DictionaryModule.masterGroupsByUserId.map(item => ({ name: item.title, value: item.id }))
  }

  private get months() {
    if (DictionaryModule.masterGroupsByUserId) {
      const foundGroup = DictionaryModule.masterGroupsByUserId.find(item => item.id === this.currentMasterGroupID)
      if (foundGroup) {
        return foundGroup.months.map((item) => ({ ...item, name: formatDate(item.name, 'yyyy LLLL') }))
      }
    }
    return []
  }

  private mounted () {
    DictionaryModule.fetchMasterGroupByMasterId(this.master?.user.id as number)
      .then(() => {
        const foundMasterGroup = DictionaryModule.masterGroupsByUserId.find(item => item.id === this.currentMasterGroupID)
        if (foundMasterGroup) {
          const currentMonth = getCurrentMonth(foundMasterGroup?.months)
          if (currentMonth) {
            this.filter = {
              ...this.filter,
              monthId: currentMonth.value as number,
            }
          }
        }
      })
      .catch(this.notifyError)
  }

  private handleRedirect(lessonId: number) {
    const lesson = this.lessons.find(item => item.id == lessonId)

    if (lesson) {
      this.$router.push(
        {
          name: 'manager.education.lessons.item',
          params: {
            groupID: this.currentMasterGroupID.toString(),
            lessonID: lesson.id.toString(),
          },
        })
    }
  }

  private getCanBeReviewed (lesson: MasterLessonShortResource) {
    return !!lesson.comment
  }

  private handleReview (lesson: MasterLessonShortResource) {
    this.review = {
      checkedAt: '',
      comment: lesson.comment as string,
      ratedAt: lesson.ratedAt as string,
      rating: lesson.rating as number,
      user: this.user as UserShortResource,
    }
    this.showModalRated = true
  }

  private fetchLessons () {
    ManagerGroupsModule.fetchGroupMasterLessons({
      masterGroupID: this.currentMasterGroupID,
      masterID: this.master?.user.id as number,
      params: this.filter,
    })
      .then((response) => {
        this.lessons = response
      })
      .catch(this.notifyError)
  }

  @Watch('filter.monthId')
  private watchFilterMonth(value: number, previosValue?: number) {
    const foundMasterGroup = DictionaryModule.masterGroupsByUserId.find(item => item.id === this.currentMasterGroupID)
    if (foundMasterGroup) {
      const currentMonth = getCurrentMonth(foundMasterGroup?.months)
      if (value !== currentMonth?.value || previosValue)
        this.$bus.$emit('changeMonth', value)
    }
  }

  @Watch('filterString')
  private watchFilter () {
    this.fetchLessons()
  }

  @Watch('currentMasterGroupID')
  private watchCurrentMasterGroupId (value: number) {
    const foundMasterGroup = DictionaryModule.masterGroupsByUserId.find(item => item.id === this.currentMasterGroupID)
    if (foundMasterGroup) {
      const currentMonth = getCurrentMonth(foundMasterGroup?.months)
      if (currentMonth) {
        this.filter = {
          ...this.filter,
          monthId: currentMonth.value as number,
        }
      }
      this.$bus.$emit('updateCurrentMasterGroup', value)
    }
  }
}
